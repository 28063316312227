import React from "react";

import { AsyncState } from "react-async";

export function SubmitState({ asyncHander }: { asyncHander: AsyncState<any> }) {
  if (asyncHander.isInitial) {
    return <span className="badge rounded-pill bg-secondary">Ready</span>;
  }
  if (asyncHander.isPending) {
    return (
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }
  if (asyncHander.error) {
    return (
      <>
        <span className="badge rounded-pill bg-danger">Failed.</span>
        <div className="alert alert-danger" role="alert">
          {asyncHander.error.message}
        </div>
      </>
    );
  }
  if (asyncHander.data) {
    return (
      <>
        <span className="badge rounded-pill bg-success">Success!</span>
        <div className="alert alert-success w-100" role="alert">
          <pre>{JSON.stringify(asyncHander.data, undefined, 2)}</pre>
        </div>
      </>
    );
  }
  return (
    <div className="alert alert-warning" role="alert">
      Unknown state. What?
    </div>
  );
}
