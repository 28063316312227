import React from "react";
import { ONE_CENT } from "../constants";

import {
  PriceGranularityPattern,
  PriceGranularityEntry,
} from "../../../lambdas/orchestra/src/core/gamCreator/price-granularity/types";

type GroupProps = {
  entryData: Partial<PriceGranularityEntry>;
  index: number;
  updatePricePatterns: (
    index: number,
    newPattern: PriceGranularityEntry
  ) => void;
};
export function Group({ entryData, index, updatePricePatterns }: GroupProps) {
  const handleChange = (type: "start" | "end" | "step") => (
    event: React.ChangeEvent
  ) => {
    const { value } = event.target as HTMLInputElement;
    const newEntryData = {
      ...entryData,
      [type]: Number(value),
    } as PriceGranularityEntry;
    updatePricePatterns(index, newEntryData);
  };

  return (
    <div className="price-granularity-group my-2">
      <div className="row">
        <div className="col-1">Start:</div>
        <div className="col-5">
          <input
            type="number"
            required={true}
            min={0}
            value={entryData?.start ?? ""}
            step={ONE_CENT}
            onChange={handleChange("start")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-1">End:</div>{" "}
        <div className="col-5">
          <input
            type="number"
            required={true}
            min={ONE_CENT}
            value={entryData?.end ?? ""}
            step={ONE_CENT}
            onChange={handleChange("end")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-1">Step:</div>{" "}
        <div className="col-5">
          <input
            type="number"
            required={true}
            step={ONE_CENT}
            min={ONE_CENT}
            value={entryData?.step ?? ""}
            onChange={handleChange("step")}
          />
        </div>
      </div>
    </div>
  );
}

type PatternManagerProps = {
  priceGranularity: PriceGranularityPattern;
  updateFormData: (key: string, value: any) => void;
};
export function PatternManager({
  priceGranularity,
  updateFormData,
}: PatternManagerProps) {
  const addPricePattern = () =>
    updateFormData("priceGranularity", [...priceGranularity, {}]);
  const updatePricePatterns = (
    index: number,
    newEntry: PriceGranularityEntry
  ) => {
    let patterns = [...priceGranularity];
    patterns[index] = newEntry;
    updateFormData("priceGranularity", patterns);
  };
  const deletePricePattern = () => {
    updateFormData("priceGranularity", priceGranularity.slice(0, -1));
  };

  const [numPatterns, setNumPatterns] = React.useState(0);
  React.useEffect(() => {
    setNumPatterns(priceGranularity.length);
  }, [priceGranularity]);
  const increasePattern = () => {
    addPricePattern();
    setNumPatterns((existing) => existing + 1);
  };
  const decreasePattern = () => {
    // If last one is not empty, alert
    const lastPriceEntry = priceGranularity[priceGranularity.length - 1];
    if (lastPriceEntry.start || lastPriceEntry.end || lastPriceEntry.step) {
      const confirmation = window?.confirm(
        "There's input in the price entry. Are you sure?"
      );
      if (!confirmation) {
        return;
      }
    }

    deletePricePattern();
    setNumPatterns((existing) => Math.max(0, existing - 1));
  };
  let InnerPatterns = [];
  for (let index = 0; index < numPatterns; index++) {
    const entryData =
      index <= priceGranularity.length
        ? priceGranularity[index]
        : { start: undefined, end: undefined, step: undefined };

    InnerPatterns.push(
      <Group
        key={`pattern-${index}`}
        entryData={entryData}
        index={index}
        updatePricePatterns={updatePricePatterns}
      />
    );
  }

  return (
    <div className="patternManager">
      <button onClick={increasePattern}>Add one</button>
      <button onClick={decreasePattern}>Remove one</button>

      {InnerPatterns}
    </div>
  );
}
