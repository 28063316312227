import React from "react";

import { useAsync } from "react-async";

import { Entry } from "../Form/Entry";
import { PatternManager } from "./PriceGranularity";
import {
  OdysseyStartRunResponseType,
  sendStartRequestToOdyssey,
} from "../Form/functions";
import { SubmitState } from "../Form/SubmitState";

export function Form() {
  const DEFAULTS = {
    adType: "display",
    priority: 12,
    copies: 1,
    priceGranularity: [{}],
  };

  const [formData, setFormData] = React.useState<{ [key: string]: any }>(
    DEFAULTS
  );

  const updateFormData = (key: string, value: any) => {
    setFormData((currentState) => {
      return { ...currentState, [key]: value };
    });
  };

  const clearFormData = () => {
    setFormData({
      ...DEFAULTS,
    });
  };

  const handleInputChange = (event: React.ChangeEvent) => {
    const target = event.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    const value =
      target.type === "checkbox"
        ? (target as HTMLInputElement).checked
        : target.value;
    const name = target.name;

    updateFormData(name, value);
  };

  const asyncHandler = useAsync({
    deferFn: async () => {
      const response = await sendStartRequestToOdyssey(formData);
      if (!response.ok) {
        console.error("Response not ok");
        console.error(response.status);
      }
      clearFormData();
      return (response.json() as unknown) as OdysseyStartRunResponseType;
    },
  });

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();
    if (!asyncHandler.isPending) {
      asyncHandler.run();
    }
  };

  return (
    <>
      <form className="w-50" autoComplete="off" onSubmit={submitHandler}>
        <h5>Network </h5>
        {/* This is very repeitive. You tried to dedupe it and it broke things. */}
        <Entry
          onChange={handleInputChange}
          name="customer"
          label="Customer Name"
          value={formData.customer ?? ""}
        />
        <Entry
          onChange={handleInputChange}
          name="networkCode"
          label="Network Code"
          type="number"
          value={formData.networkCode ?? ""}
        />
        <Entry onChange={handleInputChange} name="ssp" label="SSP" />
        {String(formData.ssp).replaceAll(" ", "_").toLowerCase() !==
          formData.ssp && formData.ssp ? (
          <div className="small mb-3 p-1 text-danger">
            Capital letters aren't valid. SSP will be parsed as{" "}
            <code>
              {String(formData.ssp).replaceAll(" ", "_").toLowerCase()}
            </code>
          </div>
        ) : undefined}
        <Entry
          onChange={handleInputChange}
          name="advertiserId"
          label="Advertiser ID (from GAM Companies)"
          type="number"
          value={formData.advertiserId ?? ""}
        />
        <Entry
          onChange={handleInputChange}
          name="traffickerId"
          label="Trafficker ID (from GAM companies)"
          type="number"
          value={formData.traffickerId ?? ""}
        />
        <Entry
          onChange={handleInputChange}
          name="salespersonId"
          label="Salesperson ID (from GAM companies)"
          type="number"
          value={formData.salespersonId ?? ""}
        />
        <hr />
        <h5>Ad info</h5>
        <Entry
          onChange={handleInputChange}
          name="adType"
          label="Ad Type"
          inputType="select"
          value={formData.adType}
        >
          <option value="display">Display</option>
          <option value="video">Video</option>
        </Entry>
        <Entry
          onChange={handleInputChange}
          name="copies"
          label="Number of creatives per line item"
          type="number"
          max="10"
          min="1"
          value={formData.copies}
        />
        <Entry
          onChange={handleInputChange}
          name="priority"
          label="Line item priority"
          type="number"
          max="16"
          min="1"
          value={formData.priority}
        />
        <Entry
          onChange={handleInputChange}
          name="creativeContent"
          label="Creative content"
          inputType="textarea"
          value={formData.creativeContent ?? ""}
        />
        <hr />
        <h5>Price granularity pattern</h5>
        <PatternManager
          priceGranularity={formData.priceGranularity}
          updateFormData={updateFormData}
        />
        <hr />
        <h5>Targeting</h5>
        <p>
          Odyssey will automatically create a per-line-item keyvalue targeting
          for Zeus.js or Iris use.
        </p>
        {formData.adType && formData.ssp && (
          <p>
            Based on your input, the keyvalue targeting will be{" "}
            <code>
              {formData.adType === "video" ? "iris_" : "zeus_"}
              {String(formData.ssp).replaceAll(" ", "_").toLowerCase()}=
              {"{cpm}"}
            </code>
          </p>
        )}
        <hr />
        <div className="">
          {!asyncHandler.isPending && <input type="submit" className="mx-1" />}
          <SubmitState asyncHander={asyncHandler} />
        </div>
      </form>
      <hr />
    </>
  );
}

export default Form;
